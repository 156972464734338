@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

.menu-item.active {
  color: #0f223c;
  background-color: #ffffff;
}

.tooltip:hover .tooltip-text {
  display: block;
}

.tooltip-text {
  display: none;
}

.tooltip-text::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.ReactModal__Overlay .ReactModal__Overlay--after-open{
  z-index: 999999999 !important;
}


.context-menu-list{
  border-radius: 5px;
}

.context-menu-list li{
  padding: 6px 12px;
  font-size: 14px;
  color: #2f2f2f;
  transition: all .3s;
  cursor: pointer;
  position: relative;
  text-wrap: nowrap;
}
.context-menu-list li .context-submenu{
  position: absolute;
  left: calc(100% + 2px);
  top:10%;
  min-width: 200px;
  background: #fff;
  transition: all .3s;
  visibility: hidden;
  opacity: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.context-menu-list li:hover > .context-submenu{
  top: 0;
  opacity: 1;
  visibility: visible;
}
.context-menu-list li:hover{
  background: #e0eaff;
}
